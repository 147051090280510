import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHeart } from '@fortawesome/free-regular-svg-icons';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';

type CardStatProps = {
  type: 'reads' | 'loves' | 'followers';
  stat: number;
  iconColor?: 'teal' | 'purple';
  textColor?: 'teal' | 'teal-ultra-dark';
  verbose?: boolean;
};

export const CardStat: React.FC<CardStatProps> = ({
  type,
  stat,
  iconColor = 'teal',
  textColor = 'teal',
  verbose = false
}) => {
  let icon = faEye;
  let description = '';
  switch (type) {
    case 'reads':
      description = ' Views';
      icon = faEye;
      break;
    case 'loves':
      description = ' Likes';
      icon = faHeart;
      break;
    case 'followers':
      description = ' Followers';
      icon = faUserFriends;
      break;
  }

  return (
    <div className="flex space-x-1 items-center">
      <FontAwesomeIcon icon={icon} className={'text-' + iconColor} />
      <p className={'font-sans-body text-sm text-' + textColor}>
        {stat}
        {verbose && description}
      </p>
    </div>
  );
};

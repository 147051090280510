export type ColorCutProps = {
  className?: string;
};

// ColorCut puts an absolutely positioned band of color at the bottom of its
// containing div. Its parent must be a position (non-static) element. className
// should specify the color of the band and its height. E.g.,
//
// 	<ColorCut className="bg-green-200 h-1/6" />
// or
// 	<ColorCut className="bg-white h-20" />
//
export const ColorCut: React.FC<ColorCutProps> = props => {
  return (
    <div className={`absolute bottom-0 left-0 right-0 z-0 ${props.className}`}></div>
  );
};

import React, { useState, useEffect } from 'react';
import { CardStat } from './card-stat';
import styles from './banner-cover-card.module.scss';
import { SafeHTML } from './basic/safe-html';
import Link from 'next/link';

// TODO: truncate genres

export type BannerCoverCardProps = {
  coverImagePath: string;
  wideCoverImagePath: string;
  title: string;
  description: string;
  genres: string[];
  type: string;
  reads: number;
  loves: number;
  link: string;

  descriptionClass: string;
  coverClassName?: string;
};

// Usage: set the actual height (by breakpoint) and max-width of this element's
// container for it to size properly.
export const BannerCoverCard: React.FC<BannerCoverCardProps> = props => {
  return (
    <Link href={props.link}>
      <div className={`w-full h-full flex justify-center items-center cursor-pointer group ${props.coverClassName || ''}`}>
        <div
          className={`
          ${styles.bannerCoverCard} relative w-full h-full bg-black shadow
          hover:border-4 border-orange box-content
      `}
        >
          <div
            className="absolute top-0 bottom-0 left-0 right-0 max-w-5xl bg-center"
            style={{
              backgroundImage: `url("//dlkfxmdtxtzpb.cloudfront.net/${props.wideCoverImagePath}")`,
              backgroundSize: 'cover'
            }}
          >
            <div
              className="absolute w-full h-full"
              style={{
                background:
                  'transparent linear-gradient(90deg, #00000000 65%, #000000 100%) 0% 0% no-repeat',
                opacity: 1,
                mixBlendMode: 'multiply'
              }}
            ></div>
          </div>
          <div className="absolute w-full h-full overflow-hidden">
            <div
              className="absolute px-4 pb-3 bottom-0 right-0 bg-black bg-opacity-50"
              style={{
                maxWidth: '350px',
                boxShadow: '0px 0px 40px 60px rgba(0, 0, 0, 0.5)'
              }}
            >
              <div className="uppercase text-xs text-teal font-bold">{props.type}</div>
              <h1 className="font-sans text-2xl font-black text-white mb-2 line-clamp-2 group-hover:text-orange">
                {props.title}
              </h1>
              <SafeHTML unsafeHTML={props.description} stripTags>
                {safeHtml => (
                  <div
                    className={`text-white font-sans-body overflow-hidden leading-tight
                      ${props.descriptionClass} ${styles.description}`}
                    dangerouslySetInnerHTML={{ __html: safeHtml }}
                  />
                )}
              </SafeHTML>
              <hr className="border-teal mt-4 mb-1" />
              <div className="flex justify-between">
                <div className="text-teal font-sans text-sm">
                  {props.genres.join(', ')}
                </div>
                <div className="flex flex-row justify-around space-x-2">
                  <CardStat stat={props.reads} type={'reads'} />
                  <CardStat stat={props.loves} type={'loves'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

import React, { ReactNode } from 'react';
import KeenSlider, { useKeenSlider } from 'keen-slider/react';
import _ from 'lodash';
import { TDetails } from 'keen-slider';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpointsForTailwind } from '../lib/keen-slider-utilities';

type CoverCarouselProps = {
  title: string;
  slides: ReactNode[];
  slidesByBreakpoint: {
    [breakpoint in 'default' | 'sm' | 'md' | 'lg' | 'xl' | '2xl']: number;
  };
  titleClass?: string;
  pageTextClass?: string;
  shifterContainerClass?: string;
  shifterColor?: string;
  arrowClassName?: string;
};

export const CoverCarousel: React.FC<CoverCarouselProps> = props => {
  const [totalPages, setTotalPages] = React.useState(1);
  const [visiblePage, setVisiblePage] = React.useState(1);

  const onSliderMove = React.useCallback((instance: KeenSlider) => {
    const details = instance.details();
    setTotalPages(getTotalPages(details));
    setVisiblePage(getVisiblePage1Indexed(details));
  }, []);

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: props.slidesByBreakpoint['default'],
    mode: 'free',
    spacing: 0,
    centered: false,
    move: onSliderMove,
    breakpoints: breakpointsForTailwind({
      sm: {
        slidesPerView: props.slidesByBreakpoint['sm'],
        mode: 'free',
        centered: false
      },
      md: {
        slidesPerView: props.slidesByBreakpoint['md'],
        mode: 'free-snap',
        centered: false
      },
      lg: {
        slidesPerView: props.slidesByBreakpoint['lg'],
        mode: 'free-snap',
        centered: false
      },
      xl: {
        slidesPerView: props.slidesByBreakpoint['xl'],
        mode: 'free-snap',
        centered: false
      },
      '2xl': {
        slidesPerView: props.slidesByBreakpoint['2xl'],
        mode: 'free-snap',
        centered: false
      }
    })
  });

  const shiftPage = React.useCallback(
    (dir: 'left' | 'right') => {
      slider.moveToSlide(shiftSnapToPageBoundaryAndLoop(slider.details(), dir));
    },
    [slider]
  );

  return (
    <div className="relative">
      {/* Heading */}
      <div className="flex justify-between md:px-4 lg:px-8 xl:px-10">
        <div className={`${props.titleClass ?? ''}`}>{props.title}</div>
        <div className={`flex space-x-1 items-center ${props.pageTextClass ?? ''}`}>
          <div className="font-bold">{visiblePage}</div>
          <div className="italic">of</div>
          <div className="font-bold">{totalPages}</div>
        </div>
      </div>

      {/* Controls & backdrop */}
      <div
        className={`${props.shifterContainerClass ?? ''}
          hidden lg:block absolute h-44 bottom-10 left-0 right-0`}
      >
        <div
          className={`${props.shifterColor ?? 'bg-teal'} h-full w-full
          flex justify-between
          text-black text-4xl font-bold`}
        >
          <div
            className={`relative z-10 w-20 left-0 ${props.shifterColor ??
              'bg-teal'} cursor-pointer
              flex items-center justify-start pl-2
              transform transition-all duration-200 hover:-translate-x-4 ${props.arrowClassName || ''}`}
            onClick={() => shiftPage('left')}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-purple" />
          </div>
          <div
            className={`relative z-10 w-20 right-0 ${props.shifterColor ??
              'bg-teal'} cursor-pointer
              flex items-center justify-end pr-2
              transform transition-all duration-200 hover:translate-x-4 ${props.arrowClassName || ''}`}
            onClick={() => shiftPage('right')}
          >
            <FontAwesomeIcon icon={faChevronRight} className="text-purple" />
          </div>
        </div>
      </div>

      {/* Carousel */}
      <div className="relative md:px-4 lg:px-8 xl:px-10">
        <div ref={sliderRef} className="keen-slider">
          {props.slides.map((slide, i) => (
            <div key={i} className="keen-slider__slide flex z-20 justify-center pt-7">
              {slide}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export function CoverCarouselForNarrowCards(
  props: Omit<CoverCarouselProps, 'slidesByBreakpoint'>
) {
  // TODO: this component is pretty awkward at the md size. The cards are a
  // little too wide to comfortable have 3, but 2 look awkward because there is
  // soooo much padding. Probably need to make the cards themselves shrink a
  // little width-wise at smaller sizes.
  return (
    <CoverCarousel
      {...props}
      slidesByBreakpoint={{ default: 2, sm: 3, md: 3, lg: 4, xl: 4, '2xl': 5 }}
    />
  );
}

// TODO: move to lib/keen-helpers.tsx

function getVisiblePage1Indexed(details: TDetails): number {
  const viewWidth = details.widthOrHeight;
  const totalPages = getTotalPages(details);

  const offsetPosition = details.position + Math.floor(viewWidth * 0.66);

  // To account for very small last pages, consider the current page to be
  // the last page if the final element is at all visible (with epsilon)
  if (
    details.positions.length > 0 &&
    details.positions[details.positions.length - 1].portion > 0.1
  ) {
    return totalPages;
  } else {
    return _.clamp(Math.floor(offsetPosition / viewWidth) + 1, 1, totalPages);
  }
}

function getTotalPages(details: TDetails): number {
  return details.positions.length > 1
    ? Math.ceil(details.size / details.slidesPerView)
    : 1;
}

function shiftSnapToPageBoundaryAndLoop(
  details: TDetails,
  dir: 'left' | 'right'
): number {
  const maxPage = Math.ceil(details.size / details.slidesPerView);
  const targetPage =
    dir === 'left'
      ? Math.ceil(details.absoluteSlide / details.slidesPerView) - 1
      : Math.ceil(details.absoluteSlide / details.slidesPerView) + 1;
  // NOTE: add maxPage to targetPage b/c `-1 % X === -1`.
  return ((targetPage + maxPage) % maxPage) * details.slidesPerView;
}

import React, { useEffect, useState } from 'react';
import { ColorCut } from '../components/layout/color-cut';
import { CoverCard, CoverCardProps } from '../components/cover-card';
import { ContentSection } from '../components/layout/content-section';
import { PageWithNav } from '../components/layout/page-with-nav';
import { PageSection } from '../components/layout/page-section';
import { CoverCarousel, CoverCarouselForNarrowCards } from '../components/cover-carousel';
import { GenreCarousel } from '../components/genre-carousel';
import { BannerCoverCard } from '../components/banner-cover-card';
import { BannerCoverCarousel } from '../components/banner-cover-carousel';
import { SpotlightCoverCard } from '../components/spotlight-cover-card';
import { Chain } from '../lib/getGraphqlClient';
import { order_by } from '../lib/generatedGraphqlClient/zeus';
import { InferGetStaticPropsType } from 'next';
import { querySeries } from '../lib/queries/series.query';
import { Head } from '../components/basic/head';
// import FreestarAdSlot from '@freestar/pubfig-adslot-react-component';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { resolveNewAppUrl } from '../helpers/link';

const enableRedirection = true;

const GENRES = [
  'Action',
  'Comedy',
  'Drama',
  'Fantasy',
  'Horror',
  'Isekai',
  'Mystery',
  'Romance',
  'Sci-Fi',
  'Slice of Life',
  'Sports',
  'Superhero',
  'Supernatural',
  'Thriller'
];

const CTAHomeModal = dynamic(
  () => import("../components/basic/cta-home").then((comp) => comp.CTAHomeModal),
  { ssr: false }
);

export const getStaticProps = async ({ params }) => {
  const trendingSeries: CoverCardProps[] = await querySeries({
    order_by: [{ trending_position: order_by.asc }],
    limit: 2
  });

  const featuredSeries: CoverCardProps[] = await querySeries({
    where: { _and: [{ feature: { _eq: '1' } }, { publish: { _eq: 1 } }] },
    order_by: [{ updated_at: order_by.desc }],
    limit: 10
  }, true);

  const originals: CoverCardProps[] = await querySeries({
    where: {
      series_tags_junctions:{
        series_tag:{
          name: {_eq: 'home_top_voyceme_originals'}
        }
      }
      },
      limit: 9
  }, true);
  originals.sort((a,b) => {return a.order[0].order - b.order[0].order})

  const popular: CoverCardProps[] = await querySeries({
    where: { publish: { _eq: 1 } },
    order_by: [{ views_counts: { count: order_by.desc_nulls_last } }],
    limit: 9
  });

  const recommended: CoverCardProps[] = await querySeries({
    where: {
      series_tags_junctions:{
        series_tag:{
          name: {_eq: 'home_recommended_for_you'}
        }
      }
      },
      limit: 9
  });
  recommended.sort((a,b) => {return a.order[0].order - b.order[0].order})

  const newStories: CoverCardProps[] = await querySeries({
    where: { chapters: {}, publish: { _eq: 1 } },
    order_by: [{ created_at: order_by.desc }],
    limit: 10
  });

  let spotlighted: CoverCardProps[] = await querySeries({
    where: {
      series_tags_junctions:{
        series_tag:{
          name: {_eq: 'home_spotlighted'}
        }
      }
      },
      limit: 9
  }, true);
  spotlighted.sort((a,b) => {return a.order[0].order - b.order[0].order})

  const topGenres = (
    await Chain().query({
      voyce_top_genres: [
        {
          order_by: [{ count: order_by.desc_nulls_last }],
          limit: 6
        },
        {
          genre: {
            title: true,
            id: true
          }
        }
      ]
    })
  ).voyce_top_genres.map(g => ({
    title: g.genre?.title,
    id: g.genre?.id
  }));
  return {
    props: {
      trendingSeries,
      featuredSeries,
      originals,
      popular,
      recommended,
      topGenres,
      newStories,
      spotlighted
    },
    revalidate: 120
  };
};

export default function Home({
  featuredSeries,
  trendingSeries,
  originals,
  popular,
  recommended,
  topGenres,
  newStories,
  spotlighted
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter();
  const [ctaHomeModalOpen, setCtaHomeModalOpen] = useState(true);

  useEffect(() => {
    if (enableRedirection) {
      router.push(resolveNewAppUrl(`/`));
    }
  }, []);

  if (router.isFallback || enableRedirection) {
    return <div>Loading...</div>;
  }

  return (
    <PageWithNav fixedNav={true}>
      <Head title="" description="" url="https://voyce.me/" />
      <CTAHomeModal
       isOpen={ctaHomeModalOpen}
       closeModal={() => {
        setCtaHomeModalOpen(false)
       }}>         
      </CTAHomeModal>
      <HeroSection trendingSeries={trendingSeries} featuredSeries={featuredSeries} />
      <StickyGenreBar />
      <FeaturedWeeklySection series={spotlighted} />
      <Originals series={originals} />
      {/* <FreestarAdSlot
        publisher={'voyce-me'}
        placementName={'voyceme_incontent_1'}
        classList={['bg-teal-lighter flex justify-center pt-16']}
      /> */}
      <Popular series={popular} />
      {/* <FreestarAdSlot
        publisher={'voyce-me'}
        placementName={'voyceme_incontent_2'}
        classList={['bg-teal-lighter flex justify-center pt-16']}
      /> */}
      <Recommended series={recommended} />
      {/* <FreestarAdSlot
        publisher={'voyce-me'}
        placementName={'voyceme_incontent_3'}
        classList={['bg-teal-lighter flex justify-center pt-16']}
      /> */}
      {/* <PopularGenres genres={topGenres} /> */}
      <NewStories series={newStories} />
    </PageWithNav>
  );
}

function HeroSection({
  trendingSeries,
  featuredSeries
}: {
  trendingSeries: CoverCardProps[];
  featuredSeries: CoverCardProps[];
}) {
  return (
    <PageSection
      className="bg-purple"
      contentClass="pt-8 lg:pt-44 lg:pb-12 lg:flex items-end"
    >
      <ContentSection
        width="lg"
        split="left"
        splitBreakpoint="lg"
        className="w-full lg:w-7/12 pb-16"
      >
        {/* TODO: need padding/margin that matches the one-sided gap between
                a lg and a md container. */}
        <h1 className="ml-4 sm:ml-8 mb-8 text-white text-3xl lg:text-5xl font-thin flex flex-col space-y-2">
          <sub className="text-base uppercase text-teal font-normal tracking-wider flex flex-row space-x-2 sm:space-x-4">
            <p>comics</p>
            <p>|</p>
            <p>novels</p>
            <p>|</p>
            <p>manga</p>
          </sub>
          Welcome to VoyceMe,
          <br />
          Home of Creativity!
        </h1>
        <div className="w-full h-80 lg:h-96">
          <BannerCoverCarousel
            slides={trendingSeries.map(s => (
              <BannerCoverCard descriptionClass="line-clamp-6" {...s} coverClassName='cw_home_main_series' />
            ))}
          />
        </div>
      </ContentSection>
      <ContentSection
        width="lg"
        split="right"
        splitBreakpoint="lg"
        className="relative bg-teal-light lg:bg-transparent w-full lg:w-5/12 pb-12"
      >
        <ColorCut className="bg-purple h-1/4" />

        <div className="z-10 relative pt-8">
          <CoverCarousel
            arrowClassName='cw_home_featured_arrows'
            title={'Featured Voyce'}
            titleClass="text-purple lg:text-white text-2xl"
            pageTextClass="text-purple lg:text-white text-xl invisible md:visible"
            slides={featuredSeries.map(s => (
              <CoverCard {...s} coverClassName='cw_home_featured_series' />
            ))}
            slidesByBreakpoint={{
              default: 2,
              sm: 2,
              md: 3,
              lg: 2,
              xl: 2,
              '2xl': 2
            }}
          />
        </div>
      </ContentSection>
    </PageSection>
  );
}

function StickyGenreBar() {
  // The container for the genre bar, marked sticky, must be a direct child
  // of the main div or it won't be able to stick to the top of the screen
  // for the entire content of the page. Thus the weirdness of this component.
  return (
    <>
      <PageSection className="lg:hidden bg-purple">
        <ContentSection width="md">
          <h2 className="text-white">Genres</h2>
        </ContentSection>
      </PageSection>
      <div className="absolute lg:sticky lg:top-0 h-0 w-full overflow-visible z-30">
        <PageSection className="h-10">
          <GenreCarousel genres={GENRES} />
        </PageSection>
      </div>
      <PageSection className="relative h-10 bg-purple">
        <ColorCut className="bg-teal-light h-1/2" />
      </PageSection>
    </>
  );
}

function FeaturedWeeklySection({ series }: { series: CoverCardProps[] }) {
  return (
    <>
      <PageSection className="bg-teal-light z-10 pb-12 pt-16 ">
        <ContentSection width="md">
          <h2 className="text-purple text-3xl">Weekly Spotlight</h2>
        </ContentSection>
      </PageSection>

      <PageSection
        className="relative z-10 bg-gradient-to-b from-teal-light to-teal-lighter"
        contentClass="lg:pb-12 lg:flex justify-center"
      >
        <ColorCut className="bg-teal-light h-1/6" />
        <ContentSection width="lg" split="left" splitBreakpoint="lg">
          <SpotlightCoverCard className="relative z-10" series={series.slice(5, 8)} />
        </ContentSection>
        <ContentSection width="lg" split="right" splitBreakpoint="lg">
          <SpotlightListing className="relative" series={series.slice(1, 7)} />
        </ContentSection>
      </PageSection>
    </>
  );
}

function SpotlightListing(props: { className?: string; series: CoverCardProps[] }) {
  const cover = 'w-52 h-64 bg-gray-lighter flex-none';
  return (
    <>
      <div
        className={`${props.className} hidden lg:flex lg:flex-wrap space-2 max-w-2xl overflow-x-hidden`}
      >
        {props.series.map(s => (
          <CoverCard key={s.slug} {...s} coverClassName='cw_home_weekly_grid_series' />
        ))}
      </div>
      <div className="block lg:hidden">
        <CoverCarousel
          title=""
          titleClass="text-purple text-2xl"
          pageTextClass="text-purple text-lg invisible md:visible"
          slidesByBreakpoint={{ default: 2, sm: 1.5, md: 3, lg: 4, xl: 4, '2xl': 4 }}
          slides={props.series.map((s, i) => (
            <CoverCard key={i} {...s} coverClassName='cw_home_vm_og_series' />
          ))}
        />
      </div>
    </>
  );
}

function Originals({ series }: { series: CoverCardProps[] }) {
  return (
    <PageSection
      className="bg-gradient-to-b from-teal-light to-teal-lighter pt-16 md:pt-0"
      contentClass="relative"
    >
      <ContentSection width="lg">
        <CoverCarouselForNarrowCards
          title="VoyceMe Originals"
          titleClass="text-purple text-2xl"
          pageTextClass="text-purple text-lg invisible md:visible"
          // hidden md:flex
          slides={series.map(s => (
            <CoverCard {...s} coverClassName='cw_home_vm_og_series' />
          ))}
          arrowClassName='cw_home_vm_og_arrows'
        />
      </ContentSection>
    </PageSection>
  );
}

function Popular({ series }: { series: CoverCardProps[] }) {
  return (
    <PageSection className="bg-teal-lighter pt-16 pb-16" contentClass="relative">
      <ContentSection width="lg">
        <CoverCarousel
          title="Popular"
          titleClass="text-purple text-2xl"
          pageTextClass="text-purple text-lg invisible md:visible"
          slides={series.map(s => (
            <CoverCard {...s} wide coverClassName='cw_home_popular_series' />
          ))}
          slidesByBreakpoint={{ default: 1.5, sm: 1.5, md: 3, lg: 3, xl: 2, '2xl': 3 }}
          arrowClassName='cw_home_popular_arrows'
        />
      </ContentSection>
    </PageSection>
  );
}

function Recommended({ series }: { series: CoverCardProps[] }) {
  return (
    <PageSection className="bg-teal-lighter pt-16" contentClass="relative">
      <ContentSection width="lg">
        <CoverCarousel
          title="Recommended"
          titleClass="text-purple text-2xl"
          pageTextClass="text-purple text-lg invisible md:visible"
          slides={series.map(s => (
            <CoverCard {...s} wide coverClassName='cw_home_recommended_series' />
          ))}
          slidesByBreakpoint={{ default: 1.5, sm: 1.5, md: 3, lg: 3, xl: 2, '2xl': 3 }}
          arrowClassName='cw_home_recommended_arrows'
        />
      </ContentSection>
    </PageSection>
  );
}

function PopularGenres({ genres }: { genres: { title: string; id: number }[] }) {
  return (
    <div className="relative z-10">
      <ColorCut className="bg-teal-dark h-1/2" />
      <PageSection className="bg-teal-lighter pb-16" contentClass="relative">
        <h1>test</h1>
      </PageSection>
    </div>
  );
}

function NewStories({ series }: { series: CoverCardProps[] }) {
  return (
    <div>
      <PageSection className="bg-teal-lighter pb-16  pt-16" contentClass="relative">
        <ContentSection width="lg">
          <CoverCarouselForNarrowCards
            title="New Stories"
            titleClass="text-purple text-2xl"
            pageTextClass="text-purple text-lg invisible md:visible"
            // hidden md:flex
            slides={series.map(s => (
              <CoverCard {...s} coverClassName='cw_home_new_series' />
            ))}
            arrowClassName='cw_home_new_arrows'
          />
        </ContentSection>
      </PageSection>
    </div>
  );
}

import React, { useMemo } from 'react';
import { CardStat } from './card-stat';
import { SafeHTML } from './basic/safe-html';
import Link from 'next/link';

import styles from './cover-card.module.scss';
import { ASSETS_BASE_URL } from '../lib/constants';

export type CoverCardProps = {
  coverImagePath: string;
  wideCoverImagePath: string;
  title: string;
  description: string;
  genres: string[];
  type: string;
  reads: number;
  loves: number;
  link: string;
  slug: string;
  animatedCoverImagePath?: string;

  showAnimation: boolean;
  order?: any;
  wide?: boolean;
  badge?: string;
  chaptersRead?: number;
  chaptersTotal?: number;
  onClick?: (card: CoverCardProps) => void;

  coverClassName?: string;
};

export const CoverCard: React.FC<CoverCardProps> = props => {
  const bottomBarHeight = 6;
  return (
    // The component floats in the center of this div to save room for
    // the border to expand on hover.
    <Link href={props.link}>
      <div
        onClick={() => {
          if (props.onClick) props.onClick(props);
        }}
        className={
          'flex justify-center items-center cursor-pointer ' +
          styles.container +
          ' ' +
          ' ' + props.coverClassName + ' ' + 
          (props.wide ? styles.wide : '')
        }
      >
        {/* Contains the cover itself, the info floating above the cover, and
          the description that appears on hover. */}
        <div
          className={
            `relative box-content border-orange group
            transition-all duration-75 border-0 hover:border-2 shadow hover:shadow-none ` +
            styles.wrapper
          }
        >
          <div
            className={'relative bg-cover ' + styles.image}
            style={{
              backgroundImage: `url("${ASSETS_BASE_URL}${
                props.coverImagePath
              }?d=${props.wide ? '395x315' : '202x350'}")`
            }}
          >
            <div
              className="absolute w-full h-full flex flex-col justify-end p-4 pb-1"
              style={{ boxShadow: 'inset 0px -200px 80px -60px rgba(0, 0, 0, 0.82)' }}
            >
              <h1 className="text-white font-sans font-bold text-lg tracking-tight line-clamp-2">
                {props.title}
              </h1>
              <p className="text-teal font-sans-body text-sm">
                {props.genres.join(', ')}
              </p>
              <p className="text-teal font-sans-body font-bold uppercase tracking-wider text-xs">
                {props.type}
              </p>
              <hr className="mt-2 mb-1 border-teal" />
              <div className="flex flex-row justify-around">
                <CardStat stat={props.reads} type={'reads'} />
                <CardStat stat={props.loves} type={'loves'} />
              </div>
            </div>
          </div>

          <div
            className="bg-orange w-full absolute"
            style={{ height: `${bottomBarHeight}px` }}
          ></div>

          {/* The pop-up description. */}
          <div
            className="absolute w-full h-full top-0
              opacity-0 hover:opacity-100
              transition-all duration-300"
          >
            <div className="absolute w-full bottom-0 h-2/5 p-4 bg-orange">
              <SafeHTML unsafeHTML={props.description} stripTags>
                {safeHtml => (
                  <div
                    className={
                      'text-white font-sans-alt text-sm leading-snug  h-full w-full line-clamp-5'
                    }
                    dangerouslySetInnerHTML={{ __html: safeHtml }}
                  />
                )}
              </SafeHTML>
            </div>
          </div>

          {props.badge && (
            <div
              className="absolute top-0 left-0 px-3 py-1.5 bg-orange
              flex items-center justify-center
              font-black text-4xl text-teal
              group-hover:text-white"
            >
              {props.badge}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
